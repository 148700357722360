<template>
  <div class="mask">
    <div class="topContainer">
      <div class="top">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="电站名称">
            <el-input v-model="form.powerStationAliasName" clearable placeholder="请输入"></el-input>
          </el-form-item>
          <!-- <el-form-item label="公司名称">
            <el-input v-model="form.companyName" clearable placeholder="请输入"></el-input>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="resert">重置</el-button>
            <el-button type="primary" @click="search">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- style="
        display: flex;
        width: calc(100vw - 80px);
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px 40px 0 40px;
        height: calc(100vh - 140px);
        overflow: auto;
      " -->
    </div>
    <div class="box">
      <div class="content" v-for="(item, index) in powerStationList" :key="index" @click="displayMask(item)">
        <div class="item">
          <div class="itemTop">
            <div class="itemName">{{ item.powerStationAliasName }}</div>
            <div class="itemUnit">
              <span>{{ item.volume >= 1000 ? item.volume / 1000 + 'mw' : item.volume + 'kw' }}</span>
            </div>
          </div>
          <div class="itemContent">
            <div class="itemContent_left">
              <div class="itemContent_left-item">
                <div>
                  <!-- <span style="color: #2acfa9; font-size: 20px">{{
                    item.monthPowerNum
                  }}</span> -->
                  <span style="color: #2acfa9;font-size: 24px;">{{
                    item.currDayPowerNumCount ? addCommas(item.currDayPowerNumCount) : 0
                  }} <span style="color: #d7d7d7; font-size: 16px">度</span> </span>
                </div>
                <div style="margin-top: 5px">
                  <span style="color: #d7d7d7; font-size: 18px">日发电量</span>
                </div>
              </div>
              <div class="itemContent_left-item" style="margin-top: 12px">
                <div>
                  <!-- <span style="color: #2acfa9; font-size: 20px">{{
                    item.currDayPowerNumCount
                  }}</span> -->
                  <span style="color: #2acfa9;font-size: 24px;">{{
                    item.totalPowerNumCount ? addCommas(item.totalPowerNumCount) : 0
                  }} <span style="color: #d7d7d7; font-size: 16px">度</span> </span>
                </div>
                <div style="margin-top: 5px">
                  <span style="color: #d7d7d7; font-size: 18px">累计发电量</span>
                </div>
              </div>
            </div>
            <div class="itemContent_right">
              <el-progress type="circle" class="sprop" :percentage="getround(item.solveProportion)" color="#00b2e6"
                :width="120"></el-progress>
              <div class="itemContent_right_content">
                待处理缺陷
                <span style="color: #d9001b; font-size: 22px; font-weight: 600">{{ item.alarmCount }}</span>
                <span style="color: #fff; font-size: 14px; font-weight: 600">
                  个</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.currPage" :page-sizes="[8, 12, 16, 20, 24]" :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper" style="width: 100%;margin: 10px 20px;"
        :total="total"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      powerStationList: [],
      userCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
      queryInfo: {
        condition: {},
        currPage: 1,
        pageSize: 8,
      },
      total: 0,
      form: {
        powerStationAliasName: "",
        companyName: "",
      },
    };
  },
  mounted() {
    this.getPowerStation();
  },
  methods: {
    async getPowerStation() {
      this.queryInfo.condition.userCode = this.userCode;
      this.queryInfo.condition.powerStationAliasName = this.form.powerStationAliasName;
      this.queryInfo.condition.companyName = this.form.companyName;
      // this.queryInfo.condition.stationCodeList = JSON.parse(localStorage.getItem('stationCodeList')) ;
      const res = await this.$http.post(
        "/powerStation/powerStationList",
         this.queryInfo
          
      );
      if (res.data.code === 200) {
        this.total = res.data.data.count;
        this.queryInfo.currPage = res.data.data.currPage;
        this.powerStationList = res.data.data.data;

      } else {
        this.$message.error(res.data);
      }
    },
    search() {
      this.getPowerStation();
    },
    resert() {
      this.form = {
        powerStationAliasName: "",
        companyName: "",
      };
      this.getPowerStation();
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getPowerStation();
    },
    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getPowerStation();
    },
    displayMask(item) {
      localStorage.setItem("powerStationCode", item.powerStationCode);
      this.$router.push(
        `/analysis/analysis/discrete?powerStationCode=${item.powerStationCode}`
      );
    },
    addCommas(number) {
      // 将数字转换为字符串，并判断是否有小数部分
      let parts = number.toString().split(".");
      let integerPart = parts[0];
      let decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 使用正则表达式，每三位加一个逗号
      let regex = /\B(?=(\d{3})+(?!\d))/g;
      integerPart = integerPart.replace(regex, ",");

      // 返回格式化后的字符串
      return integerPart + decimalPart;
    },
    getround(val) {
      let f = Math.round(val)
      return f
    },
  },
};
</script>

<style lang="scss" scoped>
.mask {
  background-color: #223f6c;
  min-height: calc(100vh - 85px);
  width: 100%;
}

.box {
  width: 1600px;
  margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  box-sizing: border-box;

  // justify-content: space-between;
  .content {
    width: 25% !important;
    // background-color: #fff;
    padding: 10px 13px 5px;
    margin-bottom: 10px;

    .itemTop {
      display: flex;
      color: #fff;
      height: 50px;
      line-height: 50px;
      justify-content: space-between;
      padding: 0 10px;
      box-sizing: border-box;
      background-color: #00b2e6;
      font-size: 22px;
      font-weight: bold;

      .itemName {
        //溢出
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .itemContent {
      display: flex;
      padding: 40px 20px;
      box-sizing: border-box;
      background-color: #14304c;
      justify-content: space-around;
      color: #8c96a7;
      // height: 300px;
      font-size: 20px;

      .itemContent_left {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
    }
  }
}

.top {
  padding-top: 20px;
  box-sizing: border-box;
  text-align: end;
}

::v-deep .el-form-item__label {
  color: #fff;
}

::v-deep .el-input__inner {
  background-color: #223f6c !important;
  color: #fff !important;
}

.itemContent_right {
  text-align: center;
}

.itemContent_right_title {
  margin-top: 5px;
  font-size: 16px;
  color: #fff;
}

// ::v-deep .el-progress-circle {
//   width: 100px !important;
//   height: 100px!important;
// }
.sprop {
  position: relative;

  &::after {
    position: absolute;
    content: '消缺率';
    display: block;
    left: 50%;
    transform: translate(-50%, 150%);
    bottom: 50%;
    color: #d7d7d7;
    font-size: 16px;
  }
}

::v-deep .el-progress__text {
  // padding-left: 7px !important;
  font-size: 22px !important;
  color: #2acfa9 !important;
  padding-bottom: 20px;
}

::v-deep .el-progress-circle__track {
  stroke: #10596d; //#e5e9f2;
}

/deep/ .el-pagination button:disabled {
  background: rgba(255, 255, 255, 0);
}

/deep/ .el-pager li.active {
  color: #f2f2f2;
  background-color: #00b2e6;
  cursor: default;
}

/deep/ .el-pagination__total {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 12px;
}

/deep/.el-pager,
/deep/.btn-prev,
/deep/.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #fff;

  .number {
    background: rgba(255, 255, 255, 0);
  }
}

/deep/ .el-pagination__jump {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 15px;
}
</style>
